import React, { Component } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import imgLogo from "../images/logo-white-alpha-large.png"
import imgCoach from "../images/coach-blue-blur.jpg"
import imgSupport from "../images/support.jpg"
import "./index.css";
import Helmet from 'react-helmet';
import AOS from 'aos';
import 'aos/dist/aos.css';

class BlogPage extends Component {

  componentDidMount(){
    AOS.init({
      duration : 2000
    });
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  render() {
    return (
      <Layout>
        <SEO title="Pricing" />
        <div>
            <div>

              <div style={{ display: 'flex', backgroundImage: `url(${imgCoach})`, backgroundColor: '#fbfbfb', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'center top' }}>

                <div className="blog" style={{ marginTop: 100, marginBottom: 100 }}>
                  <div className='headline-block blog-inner'>
                    <h1 className="blog-headline">Pricing</h1>
                    <p style={{ textAlign: 'center', fontSize: '1.3em', color: '#3f6375', lineHeight: '150%' }}>
                      Submit your details below and one of our friendly team will get back to you with a bespoke price - <strong>no obligation</strong>.
                    </p>
                    <form action="/pricing-requested/" name="pricing" method="POST" className="pricing-form" data-netlify="true" data-netlify-honeypot="bot-field">
                      <p style={{ display: 'none' }}>
                        <label>Don't fill this out if you're human: <input name="bot-field" /></label>
                      </p>
                      <div className="pricing-form__group">
                        <label>Name</label>
                        <input name="name" required className="pricing-form__input" />
                      </div>
                      <div className="pricing-form__group">
                        <label>Company</label>
                        <input name="company" required className="pricing-form__input" />
                      </div>
                      <div className="pricing-form__group">
                        <label>Email</label>
                        <input name="email" type="email" required className="pricing-form__input" />
                      </div>
                      <div className="pricing-form__group">
                        <label>Phone</label>
                        <input name="phone" required className="pricing-form__input" />
                      </div>
                      <div className="pricing-form__group">
                        <label>Number of passengers</label>
                        <input name="num_passengers" required className="pricing-form__input" />
                      </div>
                      <div className="pricing-form__group">
                        <label>Average annual ticket price (GBP) - if applicable</label>
                        <input name="avg_ticket_price" required className="pricing-form__input" />
                      </div>
                      <input type="hidden" name="form-name" value="pricing" />
                      <p style={{ textAlign: 'center', paddingTop: '1em' }}>
                        <button className="btn" type="submit">Request price <i className="fas fa-arrow-right"></i></button>
                      </p>
                    </form>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </Layout>
    );
  }
};

export default BlogPage
